/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyonrails.org/"
  }, "루비 온 레일스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/rails/rails/pulls?q=is%3Apr+author%3Aheka1024"
  }, "밀러의 PR1")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/rails/kredis/pulls?q=is%3Apr+author%3Aheka1024"
  }, "밀러의 PR2")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://survey.stackoverflow.co/2023/#section-top-paying-technologies-top-paying-technologies"
  }, "StackOverflow의 연봉순 기술 4위에 위치한 루비")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jobs.apple.com/en-us/details/200540465/sr-full-stack-software-engineer-apple-music"
  }, "애플 뮤직의 루비 온 레일스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.heroku.com/matz_joins_heroku"
  }, "허로쿠(Heroku)에 합류한 마츠")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2024/"
  }, "Ruby Kaigi 2024")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://railsconf.org/"
  }, "Rails Conf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyonrails.org/world/"
  }, "Rails World")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyonrails.org/2024/6/13/tablecheck-newest-contributing-member"
  }, "레일스 재단의 멤버")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.daangn.com/jobs/5189631003/"
  }, "당근 운영개발실 채용")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.daangn.com/jobs/4300797003/"
  }, "당근 중고거래실 채용")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
